<section id="chartist-pie-charts">


  <!-- Posts chart (header) -->
  <div class="row match-height">
    <div class="col-12">
        <div class="">
            <app-line-header-chart [id]="'gradient-line-chart1'"
                                  [data]="{data:linesPosts, labels: postsLabels}"
                                  [loadingData]="loadingData">
            </app-line-header-chart>
        </div>
    </div>
  </div>

  <!-- subheader charts -->
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="card pull-up ecom-card-1 bg-white">
            <div class="card-content ecom-card2 height-180">
                <h5 class="text-muted danger position-absolute p-1">{{'dashboard/followers' | translate}}</h5>
                <div>
                    <i class="ft-pie-chart danger font-large-1 float-right p-1"></i>
                </div>
                <app-line-chart
                      [loadingData]="loadingData"
                      [data]="{data:linesFollowers, labels: labels}"
                      [lineId]="'progress-stats-line-chart'"
                      [barId]="'progress-stats-bar-chart'">
                </app-line-chart>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="card pull-up ecom-card-1 bg-white">
            <div class="card-content ecom-card2 height-180">
                <h5 class="text-muted info position-absolute p-1">{{'dashboard/Saved Offers' | translate}}</h5>
                <div>
                    <i class="ft-pie-chart info font-large-1 float-right p-1"></i>
                </div>
                <app-line-chart
                      [loadingData]="loadingData"
                      [data]="{data:linesOffers, labels: labels}"
                      [lineId]="'progress-stats-line-chart1'"
                      [barId]="'progress-stats-bar-chart1'"
                      [style]="'lineLinearStats1'"
                      [color]="'40,175,208'">
                </app-line-chart>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="card pull-up ecom-card-1 bg-white">
            <div class="card-content ecom-card2 height-180">
                <h5 class="text-muted warning position-absolute p-1">{{'dashboard/Re-newed subscriptions' | translate}}</h5>
                <div>
                    <i class="ft-pie-chart warning font-large-1 float-right p-1"></i>
                </div>
                <app-line-chart
                      [loadingData]="loadingData"
                      [data]="{data:linesSubscriptions, labels: labels}"
                      [lineId]="'progress-stats-line-chart2'"
                      [barId]="'progress-stats-bar-chart2'"
                      [style]="'lineLinearStats2'"
                      [color]="'253,185,1'">
                </app-line-chart>
            </div>
        </div>
    </div>
  </div>



  <div class="row match-height">
    <div class="col-xl-8 col-lg-12">
      <h5 class="card-title text-bold-700 my-2">{{'dashboard/Last Subscriptions' | translate}}</h5>
      <div class="card">
        <div class="card-content">
          <div id="product-summary" class="media-list position-relative">
            <div class="table-responsive">
              <table class="table table-padded table-xl mb-0" id="product-summary-table">
                <thead>
                  <tr>
                    <th class="border-top-0">{{'subscriptions/user' | translate}}</th>
                    <th class="border-top-0">{{'subscriptions/plan' | translate}}</th>
                    <th class="border-top-0">{{'subscriptions/status' | translate}}</th>
                    <th class="border-top-0">{{'subscriptions/days' | translate}}</th>
                    <th class="border-top-0">{{'common/actions' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngIf="!subscriptions?.length">
                  <tr>
                    <td colSpan="5" style="text-align: center;">
                      {{'no-subsciptions-message' | translate}}
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr *ngFor="let item of subscriptions">
                    <td class="text-truncate">{{item.profileName}}</td>
                    <td class="text-truncate">
                      <a (click)="null">{{item.months}} {{'subscriptions/months' | translate}}</a>
                    </td>
                    <td>
                      <span class="badge badge-danger" *ngIf="!item.activated">{{'subscriptions/inactive' | translate}}</span>
                      <span class="badge badge-success" *ngIf="item.activated">{{'subscriptions/active' | translate}}</span>
                    </td>
                    <td>
                      <div class="progress progress-sm mt-1 mb-0 box-shadow-2">
                        <div class="progress-bar" role="progressbar"
                            [ngClass]="{
                              'bg-gradient-x-danger': item.daysToEnd === 1 || item.daysToEnd === 2,
                              'bg-gradient-x-warning': item.daysToEnd === 3 || item.daysToEnd === 4 || item.daysToEnd === 5,
                              'bg-gradient-x-success': item.daysToEnd === 6 || item.daysToEnd === 7
                            }"
                              [style.width.%]="item.progressValue" [attr.aria-valuenow]="item.progressValue"
                              aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                    <td class="text-truncat mr-1">
                      <a [routerLink]="['/subscriptions']" [queryParams]="{ id: item.id }">
                        <i class="ft-edit blue-grey lighten-2 font-medium-5 ml-1"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-12">
      <h5 class="card-title text-bold-700 my-2">{{'dashboard/Recent Followers' | translate}}</h5>
      <div class="card">
        <!-- <div class="card-header">
          <h4 class="card-title">Recent Followers</h4>
        </div> -->
        <div class="card-content">
          <div id="recent-buyers" class="media-list">
            <div class="m-2" *ngIf="!recentFollowers?.length">
              {{'no-followers-message' | translate}}
            </div>
            <a href="#" class="media border-0" *ngFor="let item of recentFollowers">
              <div class="media-left pr-1">
                <span class="avatar avatar-md avatar-online">
                  <img class="media-object rounded-circle" [src]="item.imageUrl | imageUrl:'profile' | safe:'url'" alt="">
                </span>
              </div>
              <div class="media-body w-100">
                <span class="list-group-item-heading">{{item.name}}</span>
                <!-- <p class="list-group-item-text mb-0">
                  <span class="blue-grey lighten-2 font-small-3"> #INV-12332 </span>
                </p> -->
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- counts -->
  <div class="row">

    <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
            <div class="card-header">
              <p class="darken-2 card-title">{{'dashboard/Total Posts' | translate}}</p>
              <h3 class="display-4 blue-grey lighten-2 text-center pt-1">{{postsValue}}</h3>
            </div>
            <div class="card-content collapse show text-center">
              <div class="card-body">
                <app-pie-chart [data]="[{value:'\ue9d7'}]" [loadingData]="loadingData"
                    [id]="'donut-gradient-chart2'" [color]="'25,175,208'" [style]="'donutGradient3'" [fontFamily]="'feather'">

                    <ul class="list-inline clearfix mt-2">
                      <li>
                        <h1 class="blue-grey lighten-2 text-bold-400">{{posts7Days}}</h1>
                        <span class="darken-2">
                            <i class="ft-user"></i> {{'dashboard/Posts last 7 days' | translate}}</span>
                      </li>
                  </ul>
                </app-pie-chart>
              </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-header">
            <p class="darken-2 card-title">{{'dashboard/Total Followers' | translate}}</p>
            <h3 class="display-4 blue-grey lighten-2 text-center pt-1">{{followersValue}}</h3>
          </div>
            <div class="card-content collapse show text-center">
                <div class="card-body">
                    <app-pie-chart [data]="[{value:'\ue9d7'}]"
                        [loadingData]="loadingData"
                        [id]="'donut-gradient-chart'" [color]="'253,99,107'" [style]="'donutGradient1'" [fontFamily]="'feather'">

                        <ul class="list-inline clearfix mt-2">
                          <li>
                            <h1 class="blue-grey lighten-2 text-bold-400">{{followers7Days}}</h1>
                            <span class="darken-2">
                                <i class="ft-user"></i> {{'dashboard/Last 7 days' | translate}}</span>
                          </li>
                      </ul>
                    </app-pie-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-12">
      <div class="card">
        <div class="card-header">
          <p class="darken-2 card-title">{{'dashboard/Total Likes' | translate}}</p>
          <h3 class="display-4 blue-grey lighten-2 text-center pt-1">{{likesValue}}</h3>
        </div>
          <div class="card-content collapse show text-center">
              <div class="card-body">
                  <app-pie-chart [data]="[{value:'\ue9d7'}]" [loadingData]="loadingData"
                        [id]="'donut-gradient-chart1'" [color]="'105,103,206'" [style]="'donutGradient2'" [fontFamily]="'feather'">

                        <ul class="list-inline clearfix mt-2">
                          <li>
                            <h1 class="blue-grey lighten-2 text-bold-400">{{likes7Days}}</h1>
                            <span class="darken-2">
                                <i class="ft-user"></i> {{'dashboard/Last 7 days' | translate}}</span>
                          </li>
                      </ul>
                  </app-pie-chart>
              </div>
          </div>
      </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-12">
        <div class="card">
          <div class="card-header">
            <p class="darken-2 card-title">{{'dashboard/Total Comments' | translate}}</p>
            <h3 class="display-4 blue-grey lighten-2 text-center pt-1">{{commentsValue}}</h3>
          </div>
            <div class="card-content collapse show text-center">
                <div class="card-body">
                    <app-pie-chart [data]="[{value:'\ue9d7'}]" [loadingData]="loadingData"
                        [id]="'donut-gradient-chart2'" [color]="'25,175,208'" [style]="'donutGradient3'" [fontFamily]="'feather'">

                        <ul class="list-inline clearfix mt-2">
                          <li>
                            <h1 class="blue-grey lighten-2 text-bold-400">{{comments7Days}}</h1>
                            <span class="darken-2">
                                <i class="ft-user"></i> {{'dashboard/Last 7 days' | translate}}</span>
                          </li>
                      </ul>
                    </app-pie-chart>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="row" *ngIf="mostActivePost">
    <div class="col-xl-4 col-lg-12">
      <h5 class="card-title text-bold-700 my-2">{{'dashboard/Most active post' | translate}}</h5>
      <div class="card">
        <div class="card-content">
          <post-item [item]="mostActivePost" [showActions]="false" [autoLoadComments]="false"></post-item>
        </div>
      </div>
    </div>
  </div>

</section>
