import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'confirm-modal',
    templateUrl: 'confirm-modal.html',
    styleUrls: ['confirm-modal.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe]
})
export class ModalConfirmComponent implements OnInit {
    @Input() message;
    @Input() isAlert;

    constructor(private modal: NzModalRef) {
    }

    ngOnInit() { }

    close(result) {
        this.modal.destroy(result);
    }
}
