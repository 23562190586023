import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
    
    constructor(private translate: TranslateService) {
    }
    

    static format(date: Date, translate: TranslateService): string {
        let m = translate.instant('date/am');
        let hours = date.getHours();
        if (hours > 12) {
            hours -= 12;
            m = translate.instant('date/pm');
        }
        return `${hours}:${date.getMinutes()} ${m}`;
    }

    transform(value: string): any {
        const date = new Date(value);
        return FormatTimePipe.format(date, this.translate);
    }
}
