

  <div>
    <span [class.avatar]="rounded"
        [style.min-width.px]="size"
        [style.min-height.px]="size"
        [style.max-width.px]="size"
        [style.max-height.px]="size">
      <a (click)="fileInput.click()" [class.avatar]="!!rounded" class="image-button"
      [style.min-width.px]="size"
      [style.min-height.px]="size"
      [style.max-width.px]="size"
      [style.max-height.px]="size">
        <img class="media-object" [class.rounded-circle]="!!rounded" *ngIf="localImageUrl" [src]="localImageUrl | safe:'url'" alt=""
            [style.min-width.px]="size"
            [style.min-height.px]="size"
            [style.max-width.px]="size"
            [style.max-height.px]="size">
        <img class="media-object" [class.rounded-circle]="!!rounded" *ngIf="!localImageUrl && imageUrl && type" [src]="imageUrl | imageUrl:type | safe:'url'" alt=""
            [style.min-width.px]="size"
            [style.min-height.px]="size"
            [style.max-width.px]="size"
            [style.max-height.px]="size">
        <span class="ft ft-image" [class.rounded-circle]="!!rounded" *ngIf="!localImageUrl && !imageUrl">
        </span>
      </a>
    </span>
    <input type="file" #fileInput (change)="fileChanged($event)" accept="image/*">
  </div>
