import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../services/AuthService';

@Injectable({ providedIn: 'root' })
export class UnauthorizedGuard implements CanActivate {
    constructor(private authService: AuthService) {
    }

    canActivate() {
        return !this.authService.isLoggedIn || this.authService.isLoggedIn && this.authService.isTokenExpired;
    }
}
