import { TokenInterceptor } from './token.interceptor';
import { UrlInterceptor } from './url.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from './refresh-token.interceptor';

export * from './token.interceptor';
export * from './url.interceptor';
export * from './refresh-token.interceptor';


export const interceptors = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RefreshTokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: UrlInterceptor,
        multi: true
    }
];
