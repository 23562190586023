import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { ILogger } from '@microsoft/signalr';
import { AuthService } from './AuthService';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SignalRService implements ILogger {
    private hubConnection: signalR.HubConnection;
    readonly chatMessages: Subject<any> = new Subject();
    readonly notifications: Subject<{title, body, data}> = new Subject();
    readonly userStatus: Subject<any> = new Subject();

    constructor(private auth: AuthService,
                private http: HttpClient) {
        this.buildConnection();
        this.startConnection();
        auth.statusChanges.subscribe(() => {
            if (auth.isLoggedIn) {
                this.startConnection();
            }
            else {
                this.disconnect();
            }
        });
    }

    log(logLevel: signalR.LogLevel, message: string): void {
        // console.log(message);
    }

    private buildConnection() {
        const domain = environment.signalrDomain;
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(domain + '/hubs/messaging', {
                logger: this,
                accessTokenFactory: () => this.auth.token,
                // transport: signalR.HttpTransportType.WebSockets
            })
            .withAutomaticReconnect(new SignalRConnectionRetryPolicy())
            .build();
    }

    private startConnection() {
        if (!this.auth.isLoggedIn) {
            setTimeout(() => this.startConnection(), 3000);
            return;
        }

        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection Started...');
                this.registerSignalEvents();
            })
            .catch(err => {
                console.log('Error while starting connection: ' + err);
                setTimeout(() => this.startConnection(), 3000);
            });
    }

    private registerSignalEvents() {
        this.http.post('/profiles/me/set_online', null).subscribe();
        this.hubConnection.on('receiveMessage',
            (data) => this.chatMessages.next(JSON.parse(data)));
        this.hubConnection.on('receiveNotification',
            (data) => this.notifications.next(JSON.parse(data)));
        this.hubConnection.on('receiveUserStatus',
            (id, status) => this.userStatus.next({id, status}));
        this.hubConnection.onreconnected((connectionId) => {
            this.http.post('/profiles/me/set_online', null).subscribe();
        });
    }

    disconnect() {
        this.hubConnection.stop();
    }

    sendChatMessage(userId, message: string) {
        return this.hubConnection.send('sendMessage', userId, message);
    }

    requestUserStatus(userId) {
        return this.hubConnection.send('requestUserStatus', userId);
    }
}


export class SignalRConnectionRetryPolicy implements signalR.IRetryPolicy {
    private retryDelays: number[];

    constructor() {
      this.retryDelays = [];
      for (let i = 0; i < 60; i++) {
        let weight = 0;
        if (i < 10) weight = 2;
        else if (i < 20) weight = 10;
        else if (i < 30) weight = 30;
        else if (i < 40) weight = 60;
        else if (i < 50) weight = 120;
        else if (i < 60) weight = 300;
        this.retryDelays.push(i * 1000 * weight);
      }
      this.retryDelays.push(null);
    }

    nextRetryDelayInMilliseconds(retryContext: signalR.RetryContext): number {
        return this.retryDelays[retryContext.previousRetryCount];
    }
}
