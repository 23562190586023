<div class="outer">

  <div class="loading" *ngIf="loadingData">
      <!-- <mat-progress-spinner mode="indeterminate" [diameter]="35" [strokeWidth]="3"></mat-progress-spinner> -->
  </div>

  <div class="inner" [style.opacity]="loadingData ? 0 : 1">

      <div class="progress-stats-container ct-golden-section height-75 position-relative pt-3">
        <div [attr.id]="barId" class="bar-chart"></div>
        <div class="progress-stats-shadow line-chart" [attr.id]="lineId"></div>
      </div>

  </div>
</div>
