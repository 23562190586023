import { Component, OnInit, ElementRef, Input, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Chartist from 'chartist';

@Component({
    selector: 'app-line-header-chart',
    templateUrl: 'line.html',
    styleUrls: ['line.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LineHeaderChartComponent implements OnInit {
    @Input() id: string;
    @Input() loadingData: any;

    private _data: any;
    public get data(): any {
        return this._data;
    }
    @Input()
    public set data(v: any) {
        this._data = v;
        this.init();
    }

    private initialized = false;


    constructor(private elementRef: ElementRef,
                private changeDetectorRef: ChangeDetectorRef,
                private http: HttpClient) {
    }

    ngOnInit() {
        this.initialized = true;
        if (this.data) {
            this.init();
        }
    }

    private init() {
        if (!this.data || !this.data.data.length || !this.initialized) {
            return;
        }
        this.initLine();

        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
    }

    private initLine() {
        const chartElement = (this.elementRef.nativeElement as HTMLElement).querySelector(`.chart-container`);
        const chartData = {
            labels: this.data.labels,
            series: [this.data.data]
        };


        // http://gionkunz.github.io/chartist-js/api-documentation.html
        const chart = new Chartist.Line(chartElement, chartData, {
            low: 100,
            fullWidth: true,
            onlyInteger: true,
            axisY: {
                low: 0,
                scaleMinSpace: 50,
            },
            axisX: {
                showGrid: false
            },
            lineSmooth: Chartist.Interpolation.simple({
                divisor: 2
            }),
            plugins: [
                Chartist.plugins.tooltip({
                    appendToBody: true,
                    pointClass: 'ct-point'
                })
            ],
        });

        chart.on('created', (data) => {
            const defs = data.svg.querySelector('defs') || data.svg.elem('defs');
            defs.elem('linearGradient', {
                id: 'lineLinear1',
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 0
            }).elem('stop', {
                offset: '0%',
                'stop-color': 'rgba(255,108,147,0.1)'
            }).parent().elem('stop', {
                offset: '10%',
                'stop-color': 'rgba(255,108,147,1)'
            }).parent().elem('stop', {
                offset: '80%',
                'stop-color': 'rgba(255,108,147, 1)'
            }).parent().elem('stop', {
                offset: '98%',
                'stop-color': 'rgba(255,108,147, 0.1)'
            });

            // defs.elem('linearGradient', {
            //     id: 'lineLinear2',
            //     x1: 0,
            //     y1: 0,
            //     x2: 1,
            //     y2: 0
            // }).elem('stop', {
            //     offset: '0%',
            //     'stop-color': 'rgba(0,230,175,0.1)'
            // }).parent().elem('stop', {
            //     offset: '10%',
            //     'stop-color': 'rgba(0,230,175,1)'
            // }).parent().elem('stop', {
            //     offset: '80%',
            //     'stop-color': 'rgba(255,161,69, 1)'
            // }).parent().elem('stop', {
            //     offset: '98%',
            //     'stop-color': 'rgba(255,161,69, 0.1)'
            // });

            return defs;
        });

        chart.on('draw', (data) => {
            const circleRadius = 10;
            if (data.type === 'point') {
                // console.log(data);
                const circle = new Chartist.Svg('circle', {
                    cx: data.x,
                    cy: data.y,
                    'ct:value': data.value.y,
                    r: circleRadius,
                    class: data.value.y === 225 ? 'ct-point ct-point-circle' : 'ct-point ct-point-circle-transperent'
                });
                data.element.replace(circle);
            }
            if (data.type === 'line') {
                data.element.animate({
                    d: {
                        begin: 1000,
                        dur: 1000,
                        from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                        to: data.path.clone().stringify(),
                        easing: Chartist.Svg.Easing.easeOutQuint
                    }
                });
            }
        });
    }
}
