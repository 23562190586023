import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/AuthService';

@Pipe({
    name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

    constructor(private auth: AuthService) {
    }

    transform(url: string, type: string = 'profile'): string {
        if (!url) {
            return '';
        }
        return `${environment.imagesDomain}/api/files/${type}/images/${url}${url.indexOf('?') > -1 ? '&' : '?'}token=${this.auth.token}`;
    }
}
