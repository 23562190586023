
<!-- BEGIN: Header-->
<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-dark"
    [class.menu-hidden]="!showMenu">
  <div class="navbar-wrapper">
      <div class="navbar-container content">
          <div class="collapse navbar-collapse show" id="navbar-mobile">
            <ul class="nav navbar-nav mr-auto float-left">
              <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu font-large-1"> </i></a></li>
            </ul>

            <ul class="nav navbar-nav float-right">
                <li class="dropdown dropdown-language nav-item">
                  <a class="dropdown-toggle nav-link" id="dropdown-flag" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="flag-icon flag-icon-sa" *ngIf="languageService.lang === 'ar'"></i>
                    <i class="flag-icon flag-icon-us" *ngIf="languageService.lang !== 'ar'"></i>
                    <span class="selected-language"></span>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdown-flag">
                    <div class="arrow_box">
                      <a class="dropdown-item" (click)="languageService.changeLanguage('ar')"><i class="flag-icon flag-icon-sa"></i> العربية</a>
                      <a class="dropdown-item" (click)="languageService.changeLanguage('en')"><i class="flag-icon flag-icon-us"></i> English</a>
                    </div>
                  </div>
                </li>
                <li class="dropdown dropdown-notification nav-item" *ngIf="showMenu">
                  <a class="nav-link nav-link-label" href="#" data-toggle="dropdown" (click)="notificationMenuOpened()">
                    <i class="ficon ft-bell" [class.bell-shake]="unreadNotifications > 0"></i>
                    <span class="badge badge-pill badge-sm badge-info badge-up badge-glow" *ngIf="unreadNotifications > 0">{{unreadNotifications}}</span>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right" id="notification-menu" style="padding-bottom: 16px;">
                    <div class="arrow_box_right">
                      <li class="dropdown-menu-header">
                        <h6 class="dropdown-header m-0"><span class="grey darken-2">{{'common/Notifications' | translate}}</span></h6>
                      </li>
                      <perfect-scrollbar #perfectScroller (psYReachEnd)="onScroll($event)">
                            <!-- infiniteScroll
                            [infiniteScrollDistance]="2"
                            [infiniteScrollThrottle]="50"
                            (scrolled)="onScroll()" -->
                        <div style="max-height: 400px;">
                        <li class="scrollable-container notification-item media-list w-100" *ngIf="noNotifications">
                          <h6 class="media-heading no-notifications">{{'notification/no-data' | translate}}</h6>
                        </li>
                        <li class="scrollable-container notification-item media-list w-100" *ngFor="let item of notifications">
                          <a (click)="notificationClicked(item)">
                            <div class="media">
                              <div class="media-left align-self-center">
                                <i *ngIf="!item.data?.profile?.imageUrl && !item.data.group?.imageUrl" class="ft-plus-square icon-bg-circle bg-cyan"></i>
                                <img class="notification-icon-circle" *ngIf="item.data?.profile?.imageUrl" [src]="item.data.profile?.imageUrl | imageUrl:'profile' | safe:'url'">
                                <img class="ft-plus-square icon-bg-circle" *ngIf="item.data?.group?.imageUrl" [src]="item.data.group?.imageUrl | imageUrl:'group' | safe:'url'">
                              </div>
                              <div class="media-body">
                                <h6 class="media-heading">{{item.title}}</h6>
                                <p class="notification-text font-small-3 text-muted">{{item.body}}</p>
                                <small>{{item.sentAt | formatTimeAgo}}</small>
                              </div>
                            </div>
                          </a>
                        </li>
                      </div>
                    </perfect-scrollbar>

                      <!-- <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center" href="javascript:void(0)">Read all notifications</a></li> -->
                    </div>
                  </ul>
                </li>

                <li class="dropdown dropdown-notification nav-item" *ngIf="showMenu">
                  <a class="nav-link nav-link-label" [routerLink]="['/chat']" data-toggle="dropdown" (click)="messagesMenuOpened()">
                    <i class="ficon ft-mail" [class.bell-shake]="unreadMessages > 0"></i>
                    <span class="badge badge-pill badge-sm badge-info badge-up badge-glow" *ngIf="chatService.unreadMessages > 0">{{chatService.unreadMessages}}</span>
                  </a>
                </li>

                <li class="dropdown dropdown-user nav-item">
                  <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                    <span class="avatar">
                      <img [src]="imageUrl | imageUrl:'profile' | safe:'url'" alt="">
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <div class="arrow_box_right">
                      <!-- <a class="dropdown-item" href="#"><span class="avatar avatar-online"><img src="app-assets/images/portrait/small/avatar-s-19.png" alt="avatar"><span class="user-name text-bold-700 ml-1">John Doe</span></span></a>
                      <div class="dropdown-divider"></div> -->
                      <a *ngIf="showMenu" class="dropdown-item" [routerLink]="['/account/edit-profile']"><i class="ft ft-user"></i>{{'common/Edit Profile'| translate}}</a>
                      <!-- <a class="dropdown-item" [routerLink]="['/privacy']"><i class="ft ft-lock"></i>Privacy</a>
                      <a class="dropdown-item" [routerLink]="['/settings']"><i class="ft ft-settings"></i>Settings</a> -->
                      <div *ngIf="showMenu" class="dropdown-divider"></div>
                      <a class="dropdown-item" (click)="auth.logout()"><i class="ft ft-power"></i>{{'common/Logout'| translate}}</a>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
      </div>
  </div>
</nav>
<!-- END: Header-->

<!-- BEGIN: Main Menu-->
<div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow" [hidden]="!showMenu" data-scroll-to-active="true">
  <div class="navbar-header">
      <ul class="nav navbar-nav flex-row position-relative">
          <li class="nav-item mr-auto">
            <a class="navbar-brand" href="index.html">
              <img class="brand-logo" alt="" src="assets/images/logo/logo.png" />
              <h3 class="brand-text">{{'title' | translate}}</h3>
            </a>
          </li>
          <li class="nav-item d-none d-md-block nav-toggle"><a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i class="toggle-icon ft-disc font-medium-3" data-ticon="ft-disc"></i></a></li>
          <li class="nav-item d-md-none"><a class="nav-link close-navbar"><i class="ft-x"></i></a></li>
      </ul>
  </div>
  <div class="navigation-background"></div>
  <div class="main-menu-content">
      <ul class="navigation navigation-main" data-menu="menu-navigation">
          <li class=" nav-item">
            <a [routerLink]="['/home']"><i class="ft ft-home"></i><span class="menu-title">{{'home' | translate}}</span></a>
          </li>
          <li class=" nav-item">
            <a [routerLink]="['/my-posts']"><i class="ft ft-home"></i><span class="menu-title">{{'my-posts' | translate}}</span></a>
          </li>
          <!-- <li class=" nav-item">
            <a [routerLink]="['/chat']"><i class="ft ft-message-square"></i><span class="menu-title">Chat messages</span></a>
          </li> -->
          <li class=" nav-item">
            <a [routerLink]="['/offers']"><i class="ft ft-tag"></i><span class="menu-title">{{'offers' | translate}}</span></a>
          </li>
          <li class=" nav-item">
            <a [routerLink]="['/subscriptions']"><i class="ft ft-calendar"></i><span class="menu-title" >{{'subscriptions' | translate}}</span></a>
          </li>
          <!-- <li class=" nav-item">
            <a [routerLink]="['/dashboard']"><i class="ft ft-trending-up"></i><span class="menu-title">Dashboard</span></a>
          </li> -->
          <li class=" nav-item">
            <a [routerLink]="['/followers']"><i class="ft ft-users"></i><span class="menu-title">{{'followers' | translate}}</span></a>
          </li>
          <li class=" nav-item">
            <a [routerLink]="['/branches']"><i class="ft ft-users"></i><span class="menu-title">{{'branches' | translate}}</span></a>
          </li>
          <!-- <li class=" nav-item">
            <a [routerLink]="['/blocked-users']"><i class="ft ft-slash"></i><span class="menu-title">Blocked users</span></a>
          </li> -->
      </ul>
  </div>
</div>
<!-- END: Main Menu-->


<!-- BEGIN: Content-->
<!-- <div class="app-content content" *ngIf="showWrapper">
  <div class="content-wrapper">
      <div class="content-wrapper-before" *ngIf="showContentWrapperBefore"></div>
      <div class="content-header row"></div>
      <div class="content-body">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>

<div class="app-content content " style="display: flex;" *ngIf="!showWrapper">
    <router-outlet></router-outlet>
</div> -->

<div class="app-content content ">
  <router-outlet></router-outlet>
</div>
<!-- END: Content-->


<!-- BEGIN: Footer-->
<!-- <a class="btn btn-try-builder btn-bg-gradient-x-purple-red btn-glow white" href="https://www.themeselection.com/layout-builder/horizontal" target="_blank">Try Layout Builder</a> -->
<!-- <footer class="footer footer-static footer-light navbar-border navbar-shadow">
  <div class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span class="float-md-left d-block d-md-inline-block">2019 &copy; Copyright <a class="text-bold-800 grey darken-2" href="https://themeselection.com" target="_blank">ThemeSelection</a></span>
      <ul class="list-inline float-md-right d-block d-md-inline-blockd-none d-lg-block mb-0">
          <li class="list-inline-item"><a class="my-1" href="https://themeselection.com/" target="_blank"> More themes</a></li>
          <li class="list-inline-item"><a class="my-1" href="https://themeselection.com/support" target="_blank"> Support</a></li>
      </ul>
  </div>
</footer> -->
<!-- END: Footer-->
