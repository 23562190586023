import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = this.buildUrl(request.url);
        request = request.clone({ url, params: null });
        return next.handle(request);
    }

    private buildUrl(path) {
        // let baseUrl = 'http://localhost:3824';
        let baseUrl = environment.domain; // 'http://localhost:4242';
        if (path.startsWith('#')) {
            return path.substring(1);
        }
        else if (path.startsWith('~')) {
            path = path.substring(1);
        }
        else {
            baseUrl += '/api';
        }
        return baseUrl + path;
    }
}
