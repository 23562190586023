import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'posts-list',
    templateUrl: 'posts-list.html',
    styleUrls: ['posts-list.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PostsListComponent implements AfterViewInit {
    items = [];
    page = 0;
    hasMore = true;
    loading = false;
    @Input() groupId;
    @Input() profileId;

    constructor(private http: HttpClient) {
    }

    ngAfterViewInit() {
        this.loadPage();
    }

    append(post) {
        this.items.unshift(post);
    }

    postDeleted(post) {
        this.items.splice(this.items.indexOf(post), 1);
    }

    private loadPage() {
        this.loading = true;
        this.http.get<any>(this.getUrl()).subscribe(res => {
            this.loading = false;
            if (res.total <= this.items.length) {
                this.hasMore = false;
            }
            this.items.push(...res.items);
        }, () => {
            this.loading = false;
        });
    }

    private getUrl() {
        return `/posts/${this.page++}/${this.groupId ? 'group' : 'profile'}/${this.profileId || this.groupId}`;
    }

    onScroll() {
        if (this.hasMore && !this.loading) {
            this.loadPage();
        }
    }
}
