
  <!-- file:///C:/Projects/chameleon-admin-v1.2/html/ltr/vertical-modern-menu-template/card-advanced.html# -->

  <div class="card" #cardElement>

    <div class="card-header post-header">
      <div class="media border-0">
        <div class="media-left pr-1">
          <span class="avatar avatar-md">
            <img class="media-object rounded-circle" [src]="(item.profileImageUrl || profile.imageUrl) | imageUrl:'profile' | safe:'url'" alt="">
            <!-- <i></i> -->
          </span>
        </div>

        <div class="media-body w-100">
          <span class="list-group-item-heading font-medium-1">
            {{item.profileName || profile.name}}
          </span>
          <p class="list-group-item-text mb-0">
            <a [routerLink]="['/post', item.id]">
              <span class="blue-grey lighten-2 font-small-3">{{item.postedAt | formatTimeAgo}}</span>
            </a>
          </p>
        </div>
      </div>

      <div class="heading-elements" *ngIf="showActions">
        <ul class="list-inline mb-0">
          <li class="dropdown">
            <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ft ft-more-horizontal"></i>
            </a>
            <div class="dropdown-menu">
                <a (click)="deletePost()" class="dropdown-item">{{'post/delete' | translate}}</a>
                <!-- <a (click)="" class="dropdown-item" href="#">Report</a> -->
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body" *ngIf="item.content">
      <p class="card-text post-content" [attr.dir]="item.content | textDirection" [style.textAlign]="item.content | textAlign">{{item.content}}</p>
    </div>

    <img class="img-fluid" *ngIf="item.imageUrl" [src]="item.imageUrl | imageUrl:'post' | safe:'url'" alt="">

    <!-- <div class="card-body">
        <p class="card-text">Candy cupcake sugar plum oat cake wafer marzipan jujubes lollipop macaroon.</p>
        <a href="#" class="card-link">Card link</a>
        <a href="#" class="card-link">Another link</a>
    </div> -->

    <div class="card-footer border-top-blue-grey border-top-lighten-5 actions" *ngIf="showActions && item.likes">
      <span class="float-left" style="display: inline-flex;">
        <!-- <a (click)="like()" *ngIf="!item.didLike" class="card-link text-primary" style="display: inline-flex;">
          <img class="like-image" src="/assets/images/like.png" style="height: 20px;">
          <span class="margin-x-10">{{'post/like' | translate}}</span>
        </a>
        <a (click)="unlike()" *ngIf="item.didLike" class="card-link text-primary" style="display: inline-flex;">
          <img class="liked-image" src="/assets/images/liked.png" style="height: 20px;">
          <span class="margin-x-10">{{'post/unlike' | translate}}</span>
        </a> -->
      </span>
      <span class="float-right">
        <!-- <span *ngIf="item.didLike && item.likes === 1" class="card-link">{{'post/you-liked-it' | translate}}</span>
        <span *ngIf="item.didLike && item.likes > 1" class="card-link">{{'post/you' | translate}} &amp;</span> -->
        <span *ngIf="item.likes - (item.didLike ? 1 : 0)" class="card-link">{{item.likes}} {{'post/likes' | translate}}</span>
      </span>
    </div>

    <div class="card-footer border-top-blue-grey border-top-lighten-5 comments" *ngIf="showActions && (comments.length || hasMore)">

      <div *ngFor="let comment of comments" class="comment">
        <!--  -->
        <span class="avatar avatar-md">
          <img class="media-object rounded-circle" [src]="comment.profileImageUrl | imageUrl:'profile' | safe:'url'" alt="">
        </span>

        <div class="comment-content">
          <p class="list-group-item-text mb-0">
            <span class="blue-grey font-small-4" [attr.dir]="comment.userName | textDirection" [style.textAlign]="comment.userName | textAlign">{{comment.userName}}</span>
          </p>
          <p class="list-group-item-text mb-0">
            <span class="blue-grey font-small-4" [attr.dir]="comment.content | textDirection" [style.textAlign]="comment.content | textAlign">{{comment.content}}</span>
          </p>
          <p class="list-group-item-text mb-0 font-small-3 info">
            <span class="float-left">
              <span class="blue-grey lighten-2 font-small-3">{{comment.addedAt | formatTimeAgo}}</span>
              <!-- <a (click)="likeComment(comment)" *ngIf="!comment.didLike" class="card-link margin-x-20 text-primary"><i class="ft ft-thumbs-up"></i><span class="margin-x-10">{{'post/like' | translate}}</span></a> -->
              <!-- <a (click)="unlikeComment(comment)" *ngIf="comment.didLike" class="card-link margin-x-20 text-primary">{{'post/unlike' | translate}}</a> -->
              <a (click)="deleteComment(comment)" class="card-link text-primary" style="margin-left: 8px;"><span class="">{{'post/delete' | translate}}</span></a>
            </span>
            <span class="float-right">
              <!-- <span *ngIf="comment.didLike && comment.likes === 1" class="card-link">{{'post/you-liked-it' | translate}}</span>
              <span *ngIf="comment.didLike && comment.likes > 1" class="card-link">{{'post/you' | translate}} &amp;</span> -->
              <span *ngIf="comment.likes - (comment.didLike ? 1 : 0)" class="card-link">{{comment.likes}} {{'post/likes' | translate}}</span>
            </span>
          </p>
        </div>
      </div>

      <a class="load-more-comments" (click)="loadMoreComments()" *ngIf="hasMore && comments.length" class="card-link load-more-comments">{{'post-item/load-more-comments' | translate}}</a>
      <a class="load-more-comments" (click)="loadMoreComments()" *ngIf="hasMore && !comments.length" class="card-link load-more-comments">{{'post-item/load-comments' | translate}}</a>
    </div>

    <div class="card-footer border-top-blue-grey border-top-lighten-5 comment-input" *ngIf="showActions">
      <span class="avatar avatar-md">
        <img class="media-object rounded-circle" [src]="profile.imageUrl | imageUrl:'profile' | safe:'url'" alt="">
      </span>

      <p class="list-group-item-text mb-0">
        <textarea rows="1" class="form-control border-default" [formControl]="commentControl"
                  [placeholder]="'post/comment-placeholder' | translate" (keypress)="onKeyPressed($event)"
                  (input)="onPostChanged($event)"
                  [attr.dir]="postInputRtl ? 'rtl' : 'ltr'">
        </textarea>
      </p>
    </div>
</div>
