import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'api'
})
export class ApiPipe implements PipeTransform {
    constructor(private http: HttpClient) {
    }

    transform(url): any {
        return this.http.get<any>(url).pipe(map(a => a.items ? a.items : a));
    }
}
