
<div class="content-wrapper">
  <!-- <div class="content-wrapper-before" *ngIf="showContentWrapperBefore"></div> -->
  <div class="content-header row"></div>
  <div class="content-body">
    <div class="card">

      <div class="card-header">
        <h4 class="card-title">{{title | translate}}</h4>
        <div class="actions">
          <ul class="list-inline mb-0">
            <li><button class="btn" *ngIf="showAddNew" (click)="addNew()"><i class="ft ft-plus"></i></button></li>
            <li><button class="btn" *ngIf="editModeEnable" (click)="editSelected()" [disabled]="!selectedRow"><i class="ft ft-edit"></i></button></li>
            <li><button class="btn" *ngIf="showDelete" (click)="deleteSelected()" [disabled]="!selectedRow || deleting"><i class="ft ft-trash"></i></button></li>
            <li><button class="btn" (click)="refresh()"><i class="ft ft-refresh-cw"></i></button></li>
          </ul>
        </div>
      </div>

      <ng-content select="[filter]"></ng-content>

      <div class="card-content collapse show">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <ng-template *ngTemplateOutlet="headersTemplate.templateRef; context:{}"></ng-template>
              </tr>
            </thead>
            <tbody *ngIf="items && items.length">
              <tr *ngFor="let row of items; index as i"
                  [class.bg-info]="selectedRow === row"
                  [class.white]="selectedRow === row"
                  (click)="selectRow(row)"
                  (dblclick)="rowDoubleCLicked(row)">
                <ng-template *ngTemplateOutlet="cellsTemplate.templateRef; context:{ row: row, index: (i + page * pageSize), page: page }"></ng-template>
              </tr>
            </tbody>
          </table>
          <div class="no-data" *ngIf="!items || !items.length">
              No data
          </div>
        </div>
      </div>

      <div class="card-footer">
        <span class="float-right">
          <nz-pagination [nzPageIndex]="1" [nzTotal]="total" [nzPageSize]="pageSize" (nzPageIndexChange)="nzPageIndexChange($event)"></nz-pagination>
        </span>
      </div>

    </div>

  </div>
</div>
