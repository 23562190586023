import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
    name: 'firstError'
})
export class FirstErrorPipe implements PipeTransform {
    transform(control: FormGroup, prefix: string = ''): any {
        const key = Object.keys(control.errors || {})[0];
        return prefix + (key || '');
    }
}
