import { Component, OnInit, ElementRef, Input, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Chartist from 'chartist';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-line-chart',
    templateUrl: 'line.html',
    styleUrls: ['line.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LineChartComponent implements OnInit {
    @Input() loadingData: any;
    @Input() lineId: string;
    @Input() barId: string;
    @Input() color: string = '252,98,107';
    @Input() style: string = 'lineLinearStats';


    private _data: any;
    public get data(): any {
        return this._data;
    }
    @Input()
    public set data(v: any) {
        this._data = v;
        this.init();
    }

    private initialized = false;


    constructor(private elementRef: ElementRef,
                private changeDetectorRef: ChangeDetectorRef,
                private http: HttpClient) {
    }

    ngOnInit() {
        this.initialized = true;
        if (this.data) {
            this.init();
        }
    }

    private init() {
        if (!this.data || !this.data.data.length || !this.initialized) {
            return;
        }
        this.initBar();
        this.initLine();

        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
    }

    private initBar() {
        const chartElement = (this.elementRef.nativeElement as HTMLElement).querySelector('.bar-chart');
        const chartData = {
            labels: this.data.labels,
            series: [this.data.data]
        };

        // http://gionkunz.github.io/chartist-js/api-documentation.html
        const chart = new Chartist.Bar(chartElement, chartData, {
            axisY: {
                low: 0,
                high: 20,
                showGrid: false,
                showLabel: false,
                offset: 0
            },
            axisX: {
                showLabel: true,
                showGrid: false,
            },
            fullWidth: true,
        });

        chart.on('draw', (data) => {
            if (data.type === 'bar') {
                data.element.attr({
                    style: 'stroke-width: 25px'
                });
            }
        });
    }

    private initLine() {
        const chartElement = (this.elementRef.nativeElement as HTMLElement).querySelector('.line-chart');
        const chartData = {
            series: [this.data]
        };

        // http://gionkunz.github.io/chartist-js/api-documentation.html
        const chart = new Chartist.Line(chartElement, chartData, {
            axisX: {
                showLabel: false,
                showGrid: false,
            },
            axisY: {
                showLabel: false,
                showGrid: false,
                low: 0,
                high: 20,
                offset: 0
            },
            lineSmooth: Chartist.Interpolation.simple({
                divisor: 2
            }),
            plugins: [
                Chartist.plugins.tooltip({
                    appendToBody: false,
                    pointClass: 'ct-point'
                })
            ],
            fullWidth: true
        });

        chart.on('created', (data) => {
            const defs = data.svg.querySelector('defs') || data.svg.elem('defs');
            defs.elem('linearGradient', {
                id: this.style,
                x1: 0,
                y1: 0,
                x2: 1,
                y2: 0
            }).elem('stop', {
                offset: '0%',
                'stop-color': 'rgba(' + this.color + ',0.1)'
            }).parent().elem('stop', {
                offset: '10%',
                'stop-color': 'rgba(' + this.color + ',1)'
            }).parent().elem('stop', {
                offset: '80%',
                'stop-color': 'rgba(' + this.color + ', 1)'
            }).parent().elem('stop', {
                offset: '98%',
                'stop-color': 'rgba(' + this.color + ', 0.1)'
            });

            return defs;
        });

        chart.on('draw', (data) => {
            const circleRadius = 5;
            if (data.type === 'point') {
                const circle = new Chartist.Svg('circle', {
                    cx: data.x,
                    cy: data.y,
                    'ct:value': data.value.y,
                    r: circleRadius,
                    class: data.value.y === 15 ? 'ct-point ct-point-circle' : 'ct-point ct-point-circle-transperent'
                });
                data.element.replace(circle);
            }
        });
    }
}
