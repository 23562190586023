import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bgImage'
})
export class BgImagePipe implements PipeTransform {
    transform(path: string) {
        const res = !path ? '' : path;
        return res ? `url("${res}")` : null;
    }
}
