import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'onlineStatus'
})
export class OnlineStatusPipe implements PipeTransform {
    constructor(private http: HttpClient) {
    }

    transform(id) {
        return this.http.get<any>('/profiles/' + id + '/online_status')
            .pipe(map(res => res.result));
    }
}
