import { Component } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from 'src/services/LanguageService';
import { RoutingState } from 'src/services/RoutingState';
import { SignalRService } from 'src/services/SignalRService';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
  <router-outlet></router-outlet>
<!-- <div class="spinner-wrap" *ngIf="loading | async">
    <mat-progress-spinner mode="indeterminate"  [diameter]="35" [strokeWidth]="3"></mat-progress-spinner>
</div> -->
`
})
export class AppComponent {
    loading = new BehaviorSubject<boolean>(true);

    constructor(translate: TranslateService,
                router: Router,
                routingState: RoutingState,
                signalr: SignalRService,
                languageService: LanguageService) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.loading.next(false);
                const isLoginPage = !![
                    '/account/login', '/account/recover-password',
                    '/account/register', '/account/activate', '/account/info'
                ]
                    .filter(a => event.url.startsWith(a)).length;
                this.changeBodyAttributes(isLoginPage);
            }
            else if (event instanceof NavigationCancel) {
                this.loading.next(false);
            }
            else if (event instanceof NavigationStart) {
                this.loading.next(true);
            }
        });

        routingState.unloadOne();
        languageService.loadLanguage(() => {
            routingState.loadOne();
        });
    }

    changeBodyAttributes(isLoginPage: boolean) {
        if (isLoginPage) {
            document.body.classList.remove('2-columns', 'fixed-navbar');
            document.body.classList.add('1-column', 'bg-full-screen-image', 'blank-page', 'blank-page');
            document.body.dataset.col = '1-column';
        }
        else {
            document.body.classList.remove('1-column', 'bg-full-screen-image', 'blank-page', 'blank-page');
            document.body.classList.add('2-columns', 'fixed-navbar');
            document.body.dataset.col = '2-column';
            // document.body.dataset.color = 'bg-chartbg';
        }
        // document.body.dataset.color = 'bg-gradient-x-purple-red';
        document.body.dataset.color = 'bg-chartbg';
    }
}
