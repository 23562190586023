import { Pipe, PipeTransform } from '@angular/core';
import { FormatTimePipe } from './formatTime';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formatMessageDate'
})
export class FormatMessageDatePipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(value: string, displayTime = true): any {
        if (!value) {
            return '';
        }
        const date = new Date(value);
        const now = new Date();
        const isSameDay = now.getDate() === date.getDate() &&
            now.getMonth() === date.getMonth() &&
            now.getFullYear() === date.getFullYear();

        let time = '';
        if (displayTime) {
            time = FormatTimePipe.format(date, this.translate);
        }
        if (isSameDay) {
            return time;
        }

        return (now.getFullYear() === date.getFullYear() ? '' : `${date.getFullYear()}/`) +
            `${date.getMonth() + 1}/${date.getDate()}` +
            time ? ' ' + time : '';
    }
}
