import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignalRService } from './SignalRService';

interface MessagesState {
    count: number;
    canShowToast: boolean;
}

@Injectable({providedIn: 'root'})
export class ChatService {
    private messages: { [name: string]: MessagesState} = {};

    unreadMessages = 0;
    selectedChatRoom;

    constructor(signalr: SignalRService,
                private translate: TranslateService,
                private router: Router) {
        signalr.chatMessages.subscribe(data => {
            if (data.senderId !== this.selectedChatRoom) {
                if (!this.messages[data.senderId]) {
                    this.messages[data.senderId] = { count: 0, canShowToast: true };
                }
                this.messages[data.senderId].count = this.messages[data.senderId].count + 1;
                if (this.messages[data.senderId].count === 1) {
                    this.unreadMessages++;
                }
                this.showToast(data, this.messages[data.senderId]);
            }
        });
    }

    private showToast(item: any, state: MessagesState) {
        if (!state.canShowToast) {
            return;
        }
        setTimeout(() => {
            state.canShowToast = true;
        }, 5000);
        state.canShowToast = false;

        window['toastr'].info(
            this.translate.instant('notifications')['message_sent_body'].replace('%1$s', item.profileName),
            this.translate.instant('notifications')['message_sent_title'],
            {
                positionClass: 'toast-bottom-right',
                // showDuration: 5000000,
                // timeOut: 0,
                onclick: () => {
                    this.router.navigate(['/chat', item.senderId]);
                }
            }
        );
    }

    clearUnreadMessages(chatId = null) {
        if (!chatId) {
            this.unreadMessages = 0;
            this.messages = {};
        }
        else if (this.messages[chatId].count) {
            this.unreadMessages--;
        }
    }
}
