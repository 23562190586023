import { Pipe, PipeTransform } from '@angular/core';
import { FormatTimePipe } from './formatTime';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }


    transform(value: string, displayTime = false): any {
        if (!value) {
            return '';
        }
        const date = new Date(value);
        let time = '';
        if (displayTime) {
            time = ' ' + FormatTimePipe.format(date, this.translate);
        }
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}` + time;
    }
}
