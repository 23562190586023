import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Profile {
    id: string;
    name: string;
    userName: string;
    phoneNumber: string;
    description: string;
    imageUrl: string;
    cityId: string;
    address: string;
    birthDate: string;
    geolocation: { lat: number, lng: number };
    images: string[];
    privacy: {  };
    settings: {  };
    verified: boolean;
    type: 'gym' | 'user' | 'coach' | 'shop';
    completed: boolean;
}
@Injectable({providedIn: 'root'})
export class ProfileService {
    private _profile: Profile;
    readonly imageChanges = new Subject();

    public get profile(): Profile {
        return this._profile;
    }


    constructor(private http: HttpClient,
                private router: Router) {
    }

    load(force = false): Observable<Profile> {
        if (force || !this._profile) {
            return this.http.get<Profile>('/profiles/me').pipe(map(res => {
                this._profile = res;
                // if (!this.profile.completed) {
                //     localStorage.setItem('complete_registeration', 'true');
                //     this.router.navigate(['/account/register']);
                // }
                this.imageChanges.next();
                return res;
            }));
        }
        return new Observable(obs => {
            obs.next(this._profile);
            obs.complete();
        });
    }

    clear() {
        this._profile = null;
    }
}
