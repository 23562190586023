import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textDirection'
})
export class TextDirectionPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        return /[\u0600-\u06FF]/.test(value) ? 'rtl' : 'ltr';
    }
}
