import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoutingState {

    constructor() { }

    private observer: Subscriber<boolean>;
    private count: number = 0;

    ready(): Observable<boolean> {
        return new Observable<boolean>(ob => {
            this.observer = ob;
            this.checkIfReady();
        });
    }

    set(count: number) {
        this.count = count;
    }

    unloadOne() {
        this.count++;
    }

    loadOne() {
        this.count--;
        this.checkIfReady();
    }

    private checkIfReady() {
        if (this.observer) {
            const completed = this.count === 0;
            if (completed) {
                this.observer.next(true);
                this.observer.complete();
            }
        }
    }
}
