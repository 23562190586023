
  <div class="card" [formGroup]="formGroup">
    <div class="card-body input">
      <!-- <p class="card-text">Candy cupcake sugar plum oat cake wafer marzipan jujubes lollipop macaroon.</p> -->
      <textarea rows="3" class="form-control border-default" formControlName="content"
              [placeholder]="'create-post/content-placeholder' | translate"
              (input)="onPostChanged($event)"
              [attr.dir]="postInputRtl ? 'rtl' : 'ltr'"></textarea>
    </div>

    <img class="img-fluid" *ngIf="imageUrl" [src]="imageUrl | safe:'url'" alt="">
    <!-- <div class="img-fluid" *ngIf="imageUrl" [style.background-image]="imageUrl | safe">
    </div> -->

    <div class="card-footer border-top-blue-grey border-top-lighten-5 text-muted">
      <span class="float-left">
        <a (click)="fileInput.click()" class="btn btn-social-icon mb-1" [class.mr-1]="imageUrl">
          <span class="ft ft-image"></span>
        </a>
        <a (click)="removeImage()" class="btn btn-social-icon mr-1 danger" *ngIf="imageUrl">
          <span class="ft ft-x"></span>
        </a>

        <input type="file" #fileInput [hidden]="true" (change)="fileChanged($event)" accept="image/*">
      </span>
      <span class="float-right">
        <button [disabled]="isSubbmitting" (click)="submit()" class="btn btn-primary" tabindex>
          <!-- <span class="la la-bitbucket font-medium-4"></span> -->
          {{'create-post/submit' | translate}}
        </button>
      </span>
    </div>
</div>
