
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalConfirmComponent } from 'src/shared/components/confirm-modal/confirm-modal';

@Injectable({providedIn: 'root'})
export class DialogService {
    constructor(
        private modal: NzModalService,
        private translate: TranslateService) {
    }

    confirm(title, message, ok) {
        const modal = this.modal.create({
            nzTitle: this.translate.instant(title),
            nzContent: ModalConfirmComponent,
            // nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
            nzComponentParams: {
                message
            }
        });
        modal.afterClose.subscribe(result => {
            if (result === 'yes') {
                ok();
            }
        });
    }

    alert(title, message) {
        this.modal.create({
            nzTitle: this.translate.instant(title),
            nzContent: ModalConfirmComponent,
            // nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
            nzComponentParams: {
                message,
                isAlert: true
            }
        });
    }
}
