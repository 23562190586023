import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ShellComponent } from 'src/core/components';
import { AuthorizedGuard, UnauthorizedGuard } from 'src/core/guards';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/home' },
    {
        path: '', component: ShellComponent,
        canActivate: [AuthorizedGuard],
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('../modules/home/home.component').then(m => m.HomeModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'my-posts',
                loadChildren: () =>
                    import('../modules/my-posts/my-posts.component').then(m => m.MyPostsModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'chat',
                loadChildren: () =>
                    import('../modules/chat/chat').then(m => m.ChatModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'chat/:cid',
                loadChildren: () =>
                    import('../modules/chat/chat').then(m => m.ChatModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'account/edit-profile',
                loadChildren: () =>
                    import('../modules/edit-profile/edit-profile').then(m => m.EditProfileModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'account/change-email',
                loadChildren: () =>
                    import('../modules/change-email/change-email').then(m => m.ChangeEmailModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'account/change-password',
                loadChildren: () =>
                    import('../modules/change-password/change-password').then(m => m.ChangePasswordModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'account/profile',
                loadChildren: () =>
                    import('../modules/edit-profile/edit-profile').then(m => m.EditProfileModule),
                // canActivate: [AuthorizedGuard]
            },

            {
                path: 'branches/addnew',
                loadChildren: () =>
                    import('../modules/branches-edit/branches-edit').then(m => m.EditBranchModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'branches/edit/:id',
                loadChildren: () =>
                    import('../modules/branches-edit/branches-edit').then(m => m.EditBranchModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'branches',
                loadChildren: () =>
                    import('../modules/branches/branches').then(m => m.BranchesModule),
                // canActivate: [AuthorizedGuard]
            },

            {
                path: 'offers/addnew',
                loadChildren: () =>
                    import('../modules/offers-edit/offers-edit').then(m => m.EditOfferModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'offers/edit/:id',
                loadChildren: () =>
                    import('../modules/offers-edit/offers-edit').then(m => m.EditOfferModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'offers',
                loadChildren: () =>
                    import('../modules/offers/offers').then(m => m.OffersModule),
                // canActivate: [AuthorizedGuard]
            },

            {
                path: 'subscriptions/addnew',
                loadChildren: () =>
                    import('../modules/subscription-edit/subscription-edit').then(m => m.EditSubscriptionModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'subscriptions/renew/:id',
                loadChildren: () =>
                    import('../modules/subscription-edit/subscription-edit').then(m => m.EditSubscriptionModule),
                // canActivate: [AuthorizedGuard]
            },
            {
                path: 'subscriptions',
                loadChildren: () =>
                    import('../modules/subscription/subscription').then(m => m.SubscriptionModule),
                // canActivate: [AuthorizedGuard]
            },

            {
                path: 'followers',
                loadChildren: () =>
                    import('../modules/followers/followers').then(m => m.FollowersModule),
                // canActivate: [AuthorizedGuard]
            },

            {
                path: 'post/:id',
                loadChildren: () =>
                    import('../modules/post/post').then(m => m.PostModule),
                // canActivate: [AuthorizedGuard]
            }
        ]
    },
    {
        path: 'account/login',
        loadChildren: () => import('../modules/login/login.component').then(m => m.LoginModule),
        canActivate: [UnauthorizedGuard]
    },
    {
        path: 'account/register',
        loadChildren: () => import('../modules/signup/signup.component').then(m => m.SignupModule)
    },
    // {
    //     path: 'account/register',
    //     loadChildren: () => import('../modules/signup/signup.component').then(m => m.SignupModule),
    //     canActivate: [UnauthorizedGuard]
    // },
    {
        path: 'account/activate',
        loadChildren: () => import('../modules/signup-activation/activation.component').then(m => m.ActivationModule),
        canActivate: [UnauthorizedGuard]
    },
    // {
    //     path: 'account/info',
    //     loadChildren: () => import('../modules/signup-2/signup.component').then(m => m.SignupModule),
    //     canActivate: [AuthorizedGuard]
    // },
    {
        path: 'account/recover-password',
        loadChildren: () => import('../modules/login-recover-password/recover-password.component').then(m => m.RecoverPasswordModule),
        canActivate: [UnauthorizedGuard]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
