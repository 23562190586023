import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DialogService } from 'src/services/DialogService';
import { Profile, ProfileService } from 'src/services/ProfileService';
import { ModalConfirmComponent } from '../confirm-modal/confirm-modal';

declare var $;

@Component({
    selector: 'post-item',
    templateUrl: 'post-item.html',
    styleUrls: ['post-item.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PostItemComponent implements AfterViewInit {
    @Input() item;
    @Input() autoLoadComments = false;
    @Input() showActions = true;
    @Output() deleted = new EventEmitter();
    @ViewChild('cardElement') cardElement;

    comments = [];
    page = 0;
    hasMore = true;
    loading = false;
    profile: Profile;
    commentControl = new FormControl('');
    submitting = false;
    submittingLike = false;
    postInputRtl;

    constructor(private http: HttpClient,
                private dialog: DialogService,
                profileService: ProfileService) {
        this.profile = profileService.profile;
        this.postInputRtl = localStorage.getItem('language') === 'ar';
    }

    ngAfterViewInit() {
        $(this.cardElement.nativeElement).find('[data-toggle="dropdown"]').dropdown();
        if (this.autoLoadComments) {
            this.loadMoreComments(1);
        }
    }

    loadMoreComments(pageSize: number = 25) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.http.get<any>(`/posts/${this.item.id}/comments/${this.page++}?limit=${pageSize}`).subscribe(res => {
            this.loading = false;
            const items = this.page === 1 && pageSize > 1 ? (res.items as any[]).slice(1) : res.items;
            this.comments.push(...items);
            if (this.comments.length === res.total) {
                this.hasMore = false;
            }
        });
        if (pageSize === 1) {
            this.page--;
        }
    }

    onPostChanged(ev) {
        this.postInputRtl = /[\u0600-\u06FF]/.test(ev.target.value);
    }

    onKeyPressed(ev) {
        if (ev.charCode !== 13) {
            return;
        }
        ev.stopPropagation();
        ev.preventDefault();
        if ((this.commentControl.value || '').trim().length > 0) {
            this.submitting = true;
            this.commentControl.disable();
            const data = {
                content: this.commentControl.value.trim()
            };
            this.http.post<any>(`/posts/${this.item.id}/comments`, data).subscribe(res => {
                this.commentControl.enable();
                this.commentControl.setValue('');
                this.submitting = false;
                this.comments.push({
                    ...res.result,
                    profileImageUrl: this.profile.imageUrl,
                    profileName: this.profile.name
                });
            }, () => {
                // error
                this.commentControl.enable();
                this.submitting = false;
            });
        }
    }

    showComment() {
        //
    }

    deletePost() {
        if (this.submitting) {
            return;
        }
        this.dialog.confirm('post/delete-confirm/title', 'post/delete-confirm/message', () => {
            this.submitting = true;
            this.http.delete(`/posts/${this.item.id}`).subscribe(id => {
                this.submitting = false;
                this.deleted.emit(this.item);
            }, () => {
                // error
                this.submitting = false;
            });
        });
    }

    like() {
        if (this.submittingLike) {
            return;
        }
        this.submittingLike = true;
        this.item.likes = this.item.likes || 0;
        this.item.likes += 1;
        this.item.didLike = true;
        this.http.post(`/posts/${this.item.id}/like`, null).subscribe(id => {
            this.submittingLike = false;
        }, () => {
            // error
            this.submittingLike = false;
            this.item.likes -= 1;
            this.item.didLike = false;
        });
    }

    unlike() {
        if (this.submittingLike) {
            return;
        }
        this.submittingLike = true;
        this.item.likes -= 1;
        this.item.didLike = false;
        this.http.post(`/posts/${this.item.id}/unlike`, null).subscribe(id => {
            this.submittingLike = false;
        }, () => {
            // error
            this.submittingLike = false;
            this.item.likes += 1;
            this.item.didLike = true;
        });
    }

    likeComment(comment) {
        if (this.submittingLike) {
            return;
        }
        this.submittingLike = true;
        comment.likes = comment.likes || 0;
        comment.likes += 1;
        comment.didLike = true;
        this.http.post(`/posts/${this.item.id}/comments/${comment.id}/like`, null).subscribe(id => {
            this.submittingLike = false;
        }, () => {
            // error
            this.submittingLike = false;
            comment.likes -= 1;
            comment.didLike = false;
        });
    }

    unlikeComment(comment) {
        if (this.submittingLike) {
            return;
        }
        this.submittingLike = true;
        comment.likes -= 1;
        comment.didLike = false;
        this.http.post<any>(`/posts/${this.item.id}/comments/${comment.id}/unlike`, null).subscribe(id => {
            this.submittingLike = false;
        }, () => {
            // error
            this.submittingLike = false;
            comment.likes += 1;
            comment.didLike = true;
        });
    }

    deleteComment(comment) {
        if (this.submittingLike) {
            return;
        }
        this.dialog.confirm('comment/delete-confirm/title', 'comment/delete-confirm/message', () => {
            this.submittingLike = true;
            this.http.delete<any>(`/posts/${this.item.id}/comments/${comment.id}`).subscribe(res => {
                this.submittingLike = false;
                if (res.succeeded) {
                    this.comments.splice(this.comments.indexOf(comment), 1);
                }
            }, () => {
                // error
                this.submittingLike = false;
            });
        });
    }

    openMenu(event) {
        // $('.dropdown-toggle').dropdown();

        // if($(event.currentTarget).siblings('ul.dropdown-menu').length > 0){
        //   event.preventDefault();
        // }
        // event.stopPropagation();
        // $(event.currentTarget).parent().siblings().removeClass('show');
        // $(event.currentTarget).parent().toggleClass('show');

        // event.preventDefault();
        // event.stopPropagation();
        // $(this).parent().siblings().removeClass('open');
        // $(this).parent().toggleClass('open');
    }
}
