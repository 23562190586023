<div class="outer">

  <div class="loading" *ngIf="loadingData">
      <!-- <mat-progress-spinner mode="indeterminate" [diameter]="35" [strokeWidth]="3"></mat-progress-spinner> -->
  </div>

  <div class="inner" [style.opacity]="loadingData ? 0 : 1">

    <div [attr.id]="id" class="height-250 GradientlineShadow1 chart-container"></div>

  </div>
</div>
