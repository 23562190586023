
<div class="content-wrapper">
  <!-- <div class="content-wrapper-before" *ngIf="showContentWrapperBefore"></div> -->
  <div class="content-header row"></div>
  <div class="content-body">
    <div class="card">

      <div class="card-header">
        <h4 class="card-title">{{title | translate}}</h4>
      </div>

      <div class="card-body">
        <ng-content></ng-content>
      </div>

      <div class="card-footer">
        <span class="float-left">
          <button *ngIf="showGoBackButton" (click)="goBack()" class="btn btn-outline-secondary">{{'common/buttons/go-back' | translate}}</button>
          <span *ngIf="showGoBackButton" class="mx-1"></span>
          <button *ngIf="showButtons" (click)="submit()" [disabled]="formGroup.invalid || !formGroup.dirty || deleting" class="btn btn-primary">{{submitButtonText | translate}}</button>
          <span *ngIf="showButtons" class="mx-1"></span>
          <button *ngIf="showButtons && showDeleteButton" (click)="delete()" [hidden]="!id" [disabled]="deleting" class="btn btn-danger">{{'common/buttons/delete' | translate}}</button>
        </span>
        <ng-content select="[commands]"></ng-content>
      </div>

    </div>
  </div>
</div>
