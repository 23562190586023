import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textAlign'
})

export class TextAlignPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        return /[\u0600-\u06FF]/.test(value) ? 'right' : 'left';
    }
}
