import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ContentChild, Directive, Input, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalConfirmComponent } from '../confirm-modal/confirm-modal';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: 'ng-template[cells]'
})
export class DataGridCellsTemplateDirective {
    constructor(public templateRef: TemplateRef<any>) {
    }
}

@Directive({
    selector: 'ng-template[headers]'
})
export class DataGridHeadersTemplateDirective {
    constructor(public templateRef: TemplateRef<any>) {
    }
}

@Component({
    selector: 'list-view',
    templateUrl: 'list-view.html',
    styleUrls: ['list-view.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ListViewComponent implements AfterViewInit, OnDestroy {
    @ContentChild(DataGridCellsTemplateDirective) cellsTemplate: DataGridCellsTemplateDirective;
    @ContentChild(DataGridHeadersTemplateDirective) headersTemplate: DataGridHeadersTemplateDirective;
    @Input() url: string;
    @Input() title: string;
    @Input() editModeEnable = true;
    @Input() showAddNew = true;
    @Input() showDelete = true;
    @Input() usePost: boolean;
    @Input() rowSelectionEnabled = true;
    @Input() filterStrategy: 'queryParams' | 'body' = 'queryParams';
    @Input() pageSize = 10;
    @Input() queryParams: string;

    private _filterFormGroup: FormGroup;
    public get filterFormGroup(): FormGroup {
        return this._filterFormGroup;
    }
    @Input()
    public set filterFormGroup(v: FormGroup) {
        this._filterFormGroup = v;
        if (v) {
            v.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(value => this.applyFilter());
        }
    }


    private _apiUrl: string;
    public get apiUrl(): string {
        return this._apiUrl;
    }
    @Input()
    public set apiUrl(v: string) {
        // if (v && this._apiUrl) {
        //     setTimeout(() => {
        //         this.page = 0;
        //         this.loadPage();
        //     }, 1);
        // }
        const reload = !!v && !!this._apiUrl && this._apiUrl !== v;
        this._apiUrl = v;
        if (reload) {
            this.page = 0;
            this.applyFilter();
        }
    }


    selectedRow;
    page = 0;
    total = 0;
    items = [];
    deleting;

    private readonly destroyed = new Subject();

    constructor(private http: HttpClient,
                private router: Router,
                private modal: NzModalService,
                private message: NzMessageService,
                private translate: TranslateService) {
    }

    removeRow(row: any) {
        const index = this.items.indexOf(row);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

    nzPageIndexChange($event) {
        this.page = $event - 1;
        this.applyFilter();
    }

    private applyFilter() {
        let queryParams = '';
        let body = null;

        if (this.filterFormGroup) {
            if (this.filterStrategy === 'queryParams') {
                queryParams = Object
                .keys(this.filterFormGroup.value)
                .filter(a => this.filterFormGroup.value[a])
                .map(a => `${a}=${this.mapFilterValue(this.filterFormGroup.value[a])}`)
                .join('&');
            }
            else if (this.filterStrategy === 'body') {
                body = this.filterFormGroup.value;
            }
        }

        if (this.queryParams) {
            if (queryParams) {
                queryParams += '&';
            }
            queryParams += this.queryParams;
        }

        this.loadPage(queryParams, body);
    }

    private mapFilterValue(value) {
        if (value instanceof Date) {
            return value.toISOString();
        }
        return value;
    }

    private loadPage(queryParams?: string, body?: any) {
        const url = `${this.apiUrl}/${this.page}${queryParams ? '?' : ''}${queryParams ?? ''}`;
        const request = this.usePost || body ? this.http.post<any>(url, body) : this.http.get<any>(url);
        request.subscribe(result => {
            this.items = result.items;
            this.total = result.total;
        });
    }

    rowDoubleCLicked(row) {
        if (this.editModeEnable) {
            this.router.navigate([`/${this.url || this.apiUrl}/edit`, row.id]);
        }
    }

    refresh() {
        this.page = 0;
        this.applyFilter();
    }

    addNew() {
        this.router.navigate([`/${this.url || this.apiUrl}/addnew`]);
    }

    editSelected() {
        this.rowDoubleCLicked(this.selectedRow);
    }

    deleteSelected() {
        const modal = this.modal.create({
            nzTitle: this.translate.instant('common/messages/delete/title'),
            nzContent: ModalConfirmComponent,
            // nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
            nzComponentParams: {}
        });
        modal.afterClose.subscribe(result => {
            if (result === 'yes') {
                this.deleting = true;
                const url = this.apiUrl.endsWith('/page') ? this.apiUrl.replace('/page', '') :  this.apiUrl.replace('/page/', '');
                this.http.delete(`${url}/${this.selectedRow.id}`).subscribe(data => {
                    this.removeRow(this.selectedRow);
                    this.message.success(this.translate.instant('common/messages/delete-succeeded'));
                    this.deleting = false;
                }, () => {
                    this.deleting = false;
                    this.message.error(this.translate.instant('common/messages/delete-failed'));
                });
            }
        });
    }

    selectRow(row) {
        if (this.rowSelectionEnabled) {
            this.selectedRow = row;
        }
    }

    ngAfterViewInit() {
        this.applyFilter();
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
