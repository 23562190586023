import { ImageUrlPipe } from './imageUrl';
import { FormatTimeAgoPipe } from './formatTimeAgo';
import { FormatTimePipe } from './formatTime';
import { BgImagePipe } from './bgImage';
import { FormatMessageDatePipe } from './formatMessageDate';
import { FormatDatePipe } from './formatDate';
import { FirstErrorPipe } from './firstError';
import { SafePipe } from './safe';
import { ApiPipe } from './api';
import { TextDirectionPipe } from './textDirection';
import { TextAlignPipe } from './textAlign';
import { OnlineStatusPipe } from './onlineStatus';

export const pipes: any[] = [
    ImageUrlPipe,
    BgImagePipe,
    FormatTimeAgoPipe,
    FormatTimePipe,
    FormatDatePipe,
    FormatMessageDatePipe,
    FirstErrorPipe,
    SafePipe,
    TextDirectionPipe,
    TextAlignPipe,
    ApiPipe,
    OnlineStatusPipe,
];

export const pipeServices: any[] = [

];
