import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DialogService } from 'src/services/DialogService';

@Component({
    selector: 'create-post',
    templateUrl: 'create-post.html',
    styleUrls: ['create-post.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreatePostComponent implements OnInit {
    @Input() groupId;
    @Output() created = new EventEmitter();

    formGroup: FormGroup;
    isSubbmitting: boolean = false;
    errorMessage = '';
    imageUrl;
    private imageFile: File;
    postInputRtl;

    constructor(private http: HttpClient,
                formBuilder: FormBuilder,
                private message: NzMessageService,
                private translate: TranslateService,
                private dialogService: DialogService) {
        this.formGroup = formBuilder.group({
            content: ['', [Validators.required]]
        });
        this.postInputRtl = localStorage.getItem('language') === 'ar';
    }

    onPostChanged(ev) {
        this.postInputRtl = /[\u0600-\u06FF]/.test(ev.target.value);
    }

    removeImage() {
        this.imageUrl = null;
        this.imageFile = null;
    }

    fileChanged(ev) {
        const file = ev.target.files[0];
        if (!file) {
            return;
        }
        if ((file.size / 1024 / 1024) > 2) {
            this.dialogService.alert('common/error', 'post/image-exceeded-limit-message');
            return;
        }

        this.imageFile = file;
        const reader = new FileReader();
        reader.onload = (event: any) => {
            // this.imageUrl = 'url("' + event.target.result + '")';
            this.imageUrl = event.target.result;
        };
        reader.readAsDataURL(this.imageFile);
    }

    ngOnInit() {
    }

    submit() {
        if (this.isSubbmitting || (this.formGroup.invalid && !this.imageFile)) {
            return;
        }
        this.isSubbmitting = true;

        const data = {
            email: this.groupId,
            content: this.formGroup.value.content
        };
        const form = new FormData();
        form.append('model', JSON.stringify(data));
        form.append('image', this.imageFile);
        this.http.post<any>('/posts', form).subscribe((result) => {
            this.isSubbmitting = false;
            if (result.succeeded) {
                this.imageFile = null;
                this.imageUrl = null;
                this.formGroup.setValue({ content: '' });
                this.created.emit(result.result);
            }
            else {
                this.showError(result.error);
            }
        }, (error) => {
            this.showError(error.error);
            this.isSubbmitting = false;
        });
    }

    private showError(result) {
        this.errorMessage = JSON.stringify(result);
        this.message.error(this.translate.instant('post/messages/submit-failed'));
    }
}
