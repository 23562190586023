import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/AuthService';
import { ChatService } from 'src/services/ChatService';
import { ProfileService } from 'src/services/ProfileService';
import { SignalRService } from 'src/services/SignalRService';
import { ar_EG, en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { LanguageService } from 'src/services/LanguageService';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit {
    unreadNotifications = 0;
    notifications = [];
    hasMoreNorifications;
    loadingNotifications;
    notificationsPage = 0;
    noNotifications = false;
    imageUrl;
    showMenu = false;

    constructor(private translate: TranslateService,
                public languageService: LanguageService,
                private router: Router,
                public http: HttpClient,
                public auth: AuthService,
                public profileService: ProfileService,
                signalr: SignalRService,
                public chatService: ChatService) {
        // document.body.classList.add('menu-expanded');router: Router,
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd && !event.url.startsWith('/account/profile')) {
                this.showMenu = true;
            }
        });
        profileService.imageChanges.subscribe(() => {
            this.setImageUrl();
        });
        this.setImageUrl();
        this.showMenu = profileService.profile.completed;
        signalr.notifications.subscribe(data => {
            this.setNotifications([data], this.notifications.length + 1, true);
        });
        window['toastr'].options.onclick = (data) => this.notificationClicked(data);
    }

    private setImageUrl() {
        this.imageUrl = this.profileService.profile?.imageUrl + '?r=' + Math.random();
    }

    ngOnInit() {
        (window as any).initApp();
        this.loadNotifications();
    }

    private loadNotifications() {
        this.loadingNotifications = true;
        this.http.get<any>('/notifications/' + this.notificationsPage++)
            .subscribe(res => {
                this.setNotifications(res.items, res.total);
            });
    }

    private setNotifications(items: any[], total: number, atFirst: boolean = false) {
        items = items.map(a => this.mapNotification(a));
        let subtract = 0;

        if (atFirst) {
            items.forEach(item => {
                if (item.data.type === 'message_sent') {
                    if (this.chatService.selectedChatRoom === item.data.profileId) {
                        subtract++;
                    }

                    // remove old message's notifications sent from same chat/sender
                    for (const notification of this.notifications) {
                        if (notification && notification.data.type === 'message_sent' &&
                            notification.data.profileId === item.data.profileId &&
                            !notification.read) {
                            const index = this.notifications.indexOf(notification);
                            this.notifications.splice(index, 1);
                            break;
                        }
                    }
                }
            });
            this.notifications = [...items, ...this.notifications];
            this.showToast(items);
        }
        else {
            this.notifications.push(...items);
        }
        this.unreadNotifications = this.notifications.filter(a => !a.read).length - subtract;
        this.hasMoreNorifications = total > this.notifications.length;
        this.loadingNotifications = false;
        this.noNotifications = this.notifications.length === 0;
    }

    private mapNotification(a: any) {
        a.data = typeof a.data === 'string' ? JSON.parse(a.data) : a.data;
        a.title = a.title || this.translate.instant('notifications')[a.data.type + '_title'] || 'test title';
        a.body = a.body || this.translate.instant('notifications')[a.data.type + '_body'] || 'test body';
        if (!a.data.profile) {
            a.data.profile = a.profile;
        }
        if (!a.data.group) {
            a.data.group = a.group;
        }

        const groupName = a.data?.group?.name;
        const profileName = a.data?.profile?.name;
        a.body = a.body.replace('%1$s', profileName);
        switch (a.body) {
            case 'new_group_member_body': a.body = a.body.replace('%2$s', groupName); break;
            case 'join_to_group_request_body': a.body = a.body.replace('%2$s', groupName); break;
            case 'accepted_in_group_body': a.body = a.body.replace('%1$s', groupName); break;
        }

        return a;
    }

    notificationClicked(item) {
        if (item.data.type === 'post_liked' ||
            item.data.type === 'comment_added' ||
            item.data.type === 'comment_liked') {
            this.router.navigate(['/post', item.data.postId]);
            // postId
            // commentId
            // profileId
            // profile
            // groupId
            // group
        }
        else if (item.data.type === 'accepted_in_group') {
            // groupId
            // group
        }
        else if (item.data.type === 'message_sent') {
            // item.data.messageId
            // profileId
            // profile
            this.router.navigate(['/chat', item.data.profileId]);
        }
        else if (item.data.type === 'accepted_as_follower') {
            // profileId
            // profile
        }
        else if (item.data.type === 'follow_request') {
            // profileId
            // profile
        }
    }

    notificationMenuOpened() {
        if (!this.unreadNotifications) {
            return;
        }
        this.unreadNotifications = 0;
        this.notifications.forEach(a => a.read = true);
        this.http.post<any>('/notifications/read_all', null)
            .subscribe(res => {});
    }

    messagesMenuOpened() {
        this.chatService.clearUnreadMessages();
    }

    // onScroll(ev) {
    //     // if (ev.srcElement.scrollTop > 91) {
    //     //     document.body.classList.add('show-sticky-toolbar');
    //     // }
    //     // else {
    //     //     document.body.classList.remove('show-sticky-toolbar');
    //     // }
    // }

    private showToast(items: any[]) {
        items.forEach(item => {
            window['toastr'].info(
                this.translate.instant(item.body),
                this.translate.instant(item.title),
                {
                    positionClass: 'toast-bottom-right',
                    // showDuration: 5000000,
                    // timeOut: 0,
                    onclick: () => {
                        this.notificationClicked(item);
                    }
                }
            );
        });
    }

    onScroll(event) {
        // console.log(event);
        // const isNotificationMenuOpen = document.getElementById('notification-menu').classList.contains('show');
        // if (this.hasMoreNorifications && !this.loadingNotifications && isNotificationMenuOpen) {
        if (this.hasMoreNorifications && !this.loadingNotifications) {
            this.loadNotifications();
        }
    }
}
