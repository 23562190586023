
<div>
  <p>{{(message || 'common/messages/delete/message') | translate}}</p>
</div>

<div class="modal-footer">
  <button type="button" *ngIf="!isAlert" class="btn btn-danger" (click)="close('yes')">{{'common/buttons/confirm-delete' | translate}}</button>
  <button type="button" *ngIf="!isAlert" class="btn btn-secondary" (click)="close('no')">{{'common/buttons/cancel-delete' | translate}}</button>
  <button type="button" *ngIf="isAlert" class="btn btn-secondary" (click)="close('no')">{{'common/buttons/ok' | translate}}</button>
</div>
