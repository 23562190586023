import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component,  EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormFile } from 'src/shared/models/form-file';
import { ModalConfirmComponent } from '../confirm-modal/confirm-modal';


@Component({
    selector: 'detail-view',
    templateUrl: 'detail-view.html',
    styleUrls: ['detail-view.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DetailViewComponent implements AfterViewInit {
    @Input() apiUrl: string;
    @Input() url: string;
    @Input() title: string;
    @Input() httpMethod = 'auto';
    @Input() formGroup: FormGroup;
    @Input() showButtons = true;
    @Input() showDeleteButton = true;
    @Input() showGoBackButton = true;
    @Input() showSubmitResultMessage = true;
    @Input() submitButtonText: string = 'common/buttons/submit';
    @Input() formFiles: FormFile[];
    @Input() afterAddNavigationAction: 'list' | 'edit' = 'list';
    @Output() submitting = new EventEmitter<{data, cancelled}>();
    @Output() submitted = new EventEmitter<{data}>();
    @Output() dataLoaded = new EventEmitter<{data}>();
    id: number;
    loading = false;
    deleting;

    constructor(private http: HttpClient,
                private router: Router,
                route: ActivatedRoute,
                private modal: NzModalService,
                private message: NzMessageService,
                private translate: TranslateService) {
        route.params.subscribe(params => {
            this.id = params.id;
        });
    }

    goBack() {
        let url = this.url || this.apiUrl;
        // url = url.substring(0, url.lastIndexOf('/'));
        this.router.navigate([url]);
    }

    private load() {
        this.loading = true;
        this.http.get<any>(`${this.apiUrl}/${this.id}`).subscribe(data => {
            // message
            const args = { data };
            this.dataLoaded.emit(args);
            this.formGroup.reset(args.data);
            this.loading = false;
        });
    }

    submit() {
        if (this.formGroup.invalid) {
            return;
        }
        if (!this.id && this.formFiles &&
            this.formFiles.filter(a => a.required && !a.file).length) {
            return;
        }

        const args = {
            data: this.formGroup.value,
            cancelled: false
        };
        this.submitting.emit(args);
        if (args.cancelled) {
            return;
        }

        let data = args.data;

        if (this.formFiles && this.formFiles.length) {
            const formData = new FormData();
            formData.append('model', JSON.stringify(data));
            this.formFiles.forEach(f => {
                formData.append(f.name, f.file);
            });
            data = formData;
        }

        const request = this.httpMethod === 'auto' ? (this.id ? this.http.put : this.http.post) : this.http[this.httpMethod || 'post'];
        request.apply(this.http, [`${this.apiUrl}/${this.id || ''}`, data]).subscribe(result => {
            if (result.succeeded) {
                if (this.showSubmitResultMessage) {
                    this.message.success(this.translate.instant('common/messages/submit-succeeded'));
                }
                this.submitted.emit({ data });

                if (result.result) {
                    this.formGroup.reset(result.result);
                    if (this.afterAddNavigationAction === 'edit') {
                        if (!this.id) {
                            this.router.navigate([(this.url || this.apiUrl) + '/edit', result.result.id]);
                        }
                    }
                    else {
                        this.router.navigate([this.url || this.apiUrl]);
                    }
                }
                else {
                    this.formGroup.markAsPristine();
                }
            }
            else {
                // error
                this.message.error(this.translate.instant('common/messages/submit-failed'));
            }
        });
    }

    delete() {
        const modal = this.modal.create({
            nzTitle: 'Modal Title',
            nzContent: ModalConfirmComponent,
            // nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
            nzComponentParams: {}
        });
        modal.afterClose.subscribe(result => {
            if (result === 'yes') {
                this.deleting = true;
                this.http.delete(`${this.apiUrl}/${this.id}`).subscribe(data => {
                    this.message.success(this.translate.instant('common/messages/delete-succeeded'));
                    this.deleting = false;
                    this.router.navigate([(this.url || this.apiUrl)]);
                }, () => {
                    this.deleting = false;
                    this.message.error(this.translate.instant('common/messages/delete-failed'));
                });
            }
        });
    }

    ngAfterViewInit() {
        if (this.id) {
            this.load();
        }
    }
}
