import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'formatTimeAgo'
})

export class FormatTimeAgoPipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(value: any): any {
        // const date = new Date(value.replace(' ', 'T') + (value && value.indexOf('Z') < 0 ? 'Z' : ''));
        const date = new Date(value);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const years = Math.floor(diff / (60 * 60 * 1000 * 24 * 365));
        const months = Math.floor(diff / (60 * 60 * 1000 * 24 * 30));
        const weeks = Math.floor(diff / (60 * 60 * 1000 * 24 * 7));
        const days = Math.floor(diff % (60 * 60 * 1000 * 24 * 7) / (60 * 60 * 1000 * 24));
        const hours = Math.floor((diff % (60 * 60 * 1000 * 24)) / (60 * 60 * 1000));
        const mins = Math.floor(((diff % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) / (60 * 1000));
        const secs = Math.floor(((diff % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) % (60 * 1000) / 1000);

        if (years > 0) {
            return this.convert(years, 'years');
        }
        else if (months > 0) {
            return this.convert(months, 'months');
        }
        else if (weeks > 0) {
            return this.convert(weeks, 'weeks');
        }
        else if (days > 0) {
            return this.convert(days, 'days');
        }
        else if (hours > 0) {
            return this.convert(hours, 'hours');
        }
        else if (mins > 0) {
            return this.convert(mins, 'mins');
        }
        else if (secs > 0) {
            return this.convert(secs, 'secs');
        }

        return this.translate.instant('date/now');
    }

    private convert(num: number, phrase: string) {
        let n = num;
        if (num >= 3 && num <= 10) {
            n = 3;
        }
        else if (num >= 11) {
            n = 11;
        }

        const translation = this.translate.instant('date/' + phrase);
        const res = translation[n] || translation.all;
        return res.replace('$value', num);
    }
}
