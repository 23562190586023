import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { pipes, pipeServices } from './pipes';
import { libComponents } from './components';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LoadingOutline } from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const icons: IconDefinition[] = [ LoadingOutline ];

const matModules: any[] = [
];
const nzModules: any[] = [
    NzPaginationModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzSpinModule,
    NzIconModule,
    PerfectScrollbarModule
];

@NgModule({
    declarations: [
        ...pipes, ...libComponents
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...matModules,
        ...nzModules,
        TranslateModule,
        InfiniteScrollModule,
        NzIconModule.forRoot(icons)
    ],
    exports: [
        ...pipes, ...libComponents,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...matModules,
        ...nzModules,
        TranslateModule,
        InfiniteScrollModule
    ],
    providers: [
        ...pipeServices, NzMessageService, NzModalService
        // { provide: ENABLE_ALPHA_SELECTOR, useValue: false }
    ],
    // entryComponents: [...libEntryComponents]
})
export class SharedModule { }
