import { ModalConfirmComponent } from './confirm-modal/confirm-modal';
import { DashboardComponent } from './dashboard/dashboard';
import { LineHeaderChartComponent } from './dashboard/line-header/line';
import { LineChartComponent } from './dashboard/line/line';
import { PieChartComponent } from './dashboard/pie/pie';
import { ImageComponent } from './image/image';
import { CreatePostComponent } from './post-create/create-post';
import { PostItemComponent } from './post-item/post-item';
import { PostsListComponent } from './posts-list/posts-list';
import { DetailViewComponent } from './view-detail/detail-view';
import { DataGridCellsTemplateDirective, DataGridHeadersTemplateDirective, ListViewComponent } from './view-list/list-view';



export const libComponents: any[] = [
    CreatePostComponent,
    PostItemComponent,
    PostsListComponent,
    ImageComponent,
    DetailViewComponent,
    DataGridHeadersTemplateDirective,
    DataGridCellsTemplateDirective,
    ListViewComponent,
    DashboardComponent,
    PieChartComponent,
    LineChartComponent,
    LineHeaderChartComponent,
    ModalConfirmComponent
];

export const libEntryComponents: any[] = [
];
