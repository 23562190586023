import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PieDataItem } from './pie/pie';

@Component({
    selector: 'dashboard',
    templateUrl: 'dashboard.html',
    styleUrls: ['dashboard.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    @Input() groupId;
    @Output() created = new EventEmitter();

    loadingData = true;

    followersValue: any[] = [];
    likesValue: any[] = [];
    commentsValue: any[] = [];
    postsValue: any[] = [];

    followers7Days: any;
    likes7Days: any;
    comments7Days: any;
    posts7Days: any;

    labels: string[];
    postsLabels: string[];
    linesPosts: number[] = [];
    linesFollowers: number[] = [];
    linesSubscriptions: number[] = [];
    linesOffers: number[] = [];

    subscriptions: any[] = [];
    mostActivePost: any;
    recentFollowers = [];

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.load();
    }

    load() {
        this.http.get<any>('/gyms/dashboard/totals').subscribe(result => {
            this.followersValue = result.totals.find(a => a.type === 0).count;
            this.commentsValue = result.totals.find(a => a.type === 1).count;
            this.likesValue = result.totals.find(a => a.type === 2).count;
            this.postsValue = result.totals.find(a => a.type === 3).count;

            this.followers7Days = result.totals7Days.find(a => a.type === 0).count;
            this.comments7Days = result.totals7Days.find(a => a.type === 1).count;
            this.likes7Days = result.totals7Days.find(a => a.type === 2).count;
            this.posts7Days = result.totals7Days.find(a => a.type === 3).count;

            this.postsLabels = this.getSeriesLabels(result.series.find(a => a.type === 1).data[0].key, true);
            this.linesPosts = this.mapSeries(result.series.find(a => a.type === 1).data);
            this.linesFollowers = this.mapSeries(result.series.find(a => a.type === 2).data);
            this.linesOffers = this.mapSeries(result.series.find(a => a.type === 3).data);
            this.linesSubscriptions = this.mapSeries(result.series.find(a => a.type === 4).data);
            this.labels = this.getSeriesLabels(result.series.find(a => a.type === 4).data[0].key, false);

            this.loadingData = false;
        });
        this.http.get<any>('/gyms/subscription_dashboard').subscribe(result => {
            this.subscriptions = result.map(a => {
                a.progressValue = 100 * (a.daysToEnd || 0) / 7;
                return a;
            });
        });
        this.http.get<any>('/posts/most_active').subscribe(result => {
            this.mostActivePost = result[0];
        });
        this.http.get<any>('/profiles/me/followers/recent').subscribe(result => {
            this.recentFollowers = result;
        });
        window.dispatchEvent(new Event('resize'));
    }

    mapSeries(data: any[]) {
        return data.map(a => a.count).reverse();
    }

    getSeriesLabels(firstDay: string, addDate) {
        const now = new Date();
        now.setDate(now.getDate() - 7);
        // const date = new Date(firstDay);
        const date = new Date();
        const today = date.getDay() + 1;
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const res = [];
        for (let index = today; index < days.length + today; index++) {
            now.setDate(now.getDate() + 1);
            res.push(
                days[index % days.length] +
                (addDate ? ` ${now.getMonth() + 1}/${now.getDate()}` : '')
            );
        }
        return res;
    }
}
