import { Component, Input, ElementRef, ChangeDetectorRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import * as Chartist from 'chartist';
import { BehaviorSubject } from 'rxjs';
import { getColor } from '../utils';


@Component({
    selector: 'app-pie-chart',
    templateUrl: 'pie.html',
    styleUrls: ['pie.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PieChartComponent implements AfterViewInit {
    private initialized = false;
    total: number;
    legends: BehaviorSubject<LegendItem[]> = new BehaviorSubject([]);

    @Input() loadingData: any;
    @Input() fontFamily: string;
    @Input() description: string;
    @Input() options: any = {
        donut: true,
        donutWidth: 17,
        showLabel: false
    };
    // https://www.materialui.co/colors
    @Input() color = '253,99,107';
    @Input() style = 'donutGradient1';
    @Input() id: string = 'donut-gradient-chart1';

    private _data: PieDataItem[];
    public get data(): PieDataItem[] {
        return this._data;
    }
    @Input()
    public set data(v: PieDataItem[]) {
        this._data = v;
        this.init();
    }


    constructor(private elementRef: ElementRef,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.initialized = true;
        if (this.data) {
            this.init();
        }
    }

    private init() {
        if (!this.data || !this.data.length || !this.initialized) {
            return;
        }

        const value = this.data[0].value;
        const chartElement = (this.elementRef.nativeElement as HTMLElement).querySelector('.chart-container');
        const chart = new Chartist.Pie(chartElement, {
            series: [100]
        }, {
            labelInterpolationFnc: v => {
                return value;
            },
            donut: true,
            donutSolid: true,
            total: 100,
            donutWidth: 10,
        });


        chart.on('draw', (data) => {
            if (data.type === 'label') {
                if (data.index === 0) {
                    data.element.attr({
                        dx: data.element.root().width() / 2,
                        dy: data.element.root().height() / 2,
                        'font-family': this.fontFamily
                    });
                } else {
                    data.element.remove();
                }
            }
        });
        const color = this.color;
        const style = this.style;
        chart.on('created', (data) => {
            const defs = data.svg.querySelector('defs') || data.svg.elem('defs');
            defs.elem('linearGradient', {
                id: style,
                x1: 0,
                y1: 1,
                x2: 0,
                y2: 0
            }).elem('stop', {
                offset: '0%',
                'stop-color': 'rgba(' + color + ', 1)'
            }).parent().elem('stop', {
                offset: '95%',
                'stop-color': 'rgba(' + color + ', 0.3)'
            });
            return defs;
        });

        this.loadingData = false;
        this.changeDetectorRef.detectChanges();
    }
}


export interface LegendItem {
    color: string;
    title: string;
    value: number;
    precent: number;
}

export interface PieDataItem {
    color: string;
    title: string;
    value: number;
}
