import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFile } from 'src/shared/models/form-file';

@Component({
    selector: 'image',
    templateUrl: 'image.html',
    styleUrls: ['image.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageComponent implements OnInit {
    @Input() formFile: FormFile;
    @Input() formGroup: FormGroup;
    @Input() imageUrl: string;
    @Input() type: string = 'profile';
    @Input() rounded: boolean;
    @Input() size: number = 200;
    localImageUrl;

    constructor() { }

    ngOnInit() { }

    fileChanged(ev) {
        if (!this.formFile) {
            console.error('ImageComponent.formFile is required.');
            return;
        }
        this.formFile.file = ev.target.files[0];
        const reader = new FileReader();
        reader.onload = (event: any) => {
            // this.imageUrl = 'url("' + event.target.result + '")';
            this.localImageUrl = event.target.result;
            if (this.formGroup) {
                this.formGroup.markAsDirty();
            }
        };
        reader.readAsDataURL(this.formFile.file);
    }
}
