import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutingState } from '../../services/RoutingState';
import { AuthService } from '../../services/AuthService';
import { ProfileService } from 'src/services/ProfileService';

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthService,
                private profileService: ProfileService,
                private routingState: RoutingState) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // verify that user is logged in
        const auth = this.authService.verify().pipe(
            map(data => {
                if (data) {
                    // if (this.hasPermission(route.data ? route.data.resourcePerm : null)) {
                    //     return true;
                    // }
                    // else {
                    //     this.router.navigate(['/access-denied'], { queryParams: { resource: route.data.resourcePerm.resource } });
                    //     return false;
                    // }
                    return true;
                }

                // error when verify so redirect to login page with the return url
                this.router.navigate(['/account/login'], { queryParams: { returnurl: state.url } });
                return false;
            }));

        const profile = this.profileService.load().pipe(
            map(data => {
                if (data.completed || state.url.startsWith('/account/profile')) {
                    return true;
                }
                this.router.navigate(['/account/profile'], { queryParams: { returnurl: state.url } });
                return false;
            })
        );

        return forkJoin([auth, profile, this.routingState.ready()]).pipe(map(a => a[0] && a[2]));
    }

    // private hasPermission(resourcePerm: AuthorizedResource): boolean {
    //     const perm = this.permissionProvider.getPermission(resourcePerm.resource);
    //     if (resourcePerm.viewType === 'add') {
    //         return perm.add;
    //     }
    //     else if (resourcePerm.viewType === 'edit') {
    //         return perm.edit;
    //     }
    //     return perm.read;
    // }
}

// export class AuthorizedResource {
//     resource?: string;
//     viewType: 'edit' | 'add' | 'list';
// }
