<!--  [style.flexDirection]="titlesFlexDirection" [style.textAlign]="titlesAlign" -->
<div class="outer">

    <div class="loading" *ngIf="loadingData">
        <!-- <mat-progress-spinner mode="indeterminate" [diameter]="35" [strokeWidth]="3"></mat-progress-spinner> -->
    </div>

    <div class="inner" [style.opacity]="loadingData ? 0 : 1">
    <!-- <div class="inner" [style.flexDirection]="flexDirection" [style.opacity]="loadingData ? 0 : 1"> -->
        <div class="chart-container donutShadow height-150" [attr.id]="id">
            <!-- <div class="stats">
                {{total}}
            </div> -->
        </div>
        <ng-content></ng-content>

        <!-- <div class="legends" [class.horizontal-wrap]="flexDirection === 'column' || flexDirection === 'column-reverse'">
            <div class="legend" *ngFor="let item of legends | async">
                <span class="legend-bullet" [style.backgroundColor]="item.color"></span>
                <span class="legend-text">
                    {{item.title}} {{item.precent}}% ({{item.value}})
                </span>
            </div>
        </div> -->
    </div>
</div>
