import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import {  map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from './ProfileService';
import { SignalRService } from './SignalRService';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    readonly statusChanges: Subject<any> = new Subject();
    private _identity;


    private _authData: any;
    get authData(): any {
        return this._authData ? this._authData : (this._authData = JSON.parse(localStorage.getItem('auth-data')));
    }
    set authData(v: any) {
        localStorage.setItem('auth-data', JSON.stringify(v));
        this._authData = v;
    }

    get token(): string {
        return this.authData ? this.authData.access_token : null;
    }

    get identity(): any {
        return this._identity;
    }

    get isTokenExpired(): boolean {
        if (!this.authData) {
            return true;
        }

        const now: any = new Date();
        const date: any = new Date(this.authData.expires_at);
        const expiresAfter = ((date - now) / 1000);
        return expiresAfter <= 0;
    }

    private _isLoggedIn: boolean;
    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    get username(): string {
        return this.identity ? this.identity.user_name : '';
    }

    constructor(private http: HttpClient,
                private router: Router,
                private route: ActivatedRoute,
                private profileService: ProfileService
                ) {
        const authData = this.authData;
        if (authData) {
            this.set(authData);
        }
    }

    connect(username, password): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                username,
                password,
                client_id: 'spa_client',
                grant_type: 'password',
                access_type: 'offline',
                scope: 'offline_access'
            }
        });

        const connect = this.http.post<any>('/connect/token', params.toString(), {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(
            map(result => {
                this.set(result);
                this.http.post<any>('/profiles/me/update_login', null).subscribe();
            })
        );

        // const profile = this.profileService.load();

        // return forkJoin([connect, profile]).pipe(map(a => a[0]));

        return connect.pipe(
            switchMap((a, i) => {
                return this.profileService.load().pipe(map(b => a));
            })
        );
    }

    private set(result) {
        const now = new Date();
        now.setSeconds(now.getSeconds() + result.expires_in);
        result.expires_at = now.getTime();
        this.authData = result;
        this.initJwt(this.token);
        this.statusChanges.next();
    }

    private initJwt(jwtToken) {
        this._isLoggedIn = !!jwtToken;
        if (jwtToken) {
            this._identity = jwtDecode(jwtToken);
        }
        else {
            this._identity = null;
        }
    }

    refreshAccessToken(): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                client_id: 'spa_client',
                grant_type: 'refresh_token',
                refresh_token: this.authData ? this.authData.refresh_token : ''
            }
        });
        return this.http.post<any>('/connect/token', params.toString(), {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(
            map(result => {
                this.set(result);
                return this.token;
            })
        );
    }

    verify(): Observable<boolean> {
        return of(this.isLoggedIn && !this.isTokenExpired && this.identity.profile_type === 'Gym');
    }

    logout() {
        this.profileService.clear();
        this._isLoggedIn = false;
        localStorage.clear();
        this.authData = null;
        this.statusChanges.next();
        this.router.navigate(['/account/login'], {
            queryParams: { returnurl: this.route.snapshot.url.join('') } // window.location.pathname
        });
    }
}
