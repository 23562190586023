import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { doesNotReject } from 'assert';
import { ar_EG, en_US, NzI18nService } from 'ng-zorro-antd/i18n';

@Injectable({providedIn: 'root'})
export class LanguageService {
    lang = localStorage.getItem('language');

    constructor(private translate: TranslateService,
                private nzI18nService: NzI18nService) { }

    loadLanguage(done) {
        return this.changeLanguage(this.lang, false, done);
    }

    changeLanguage(lang, reload = true, done = null) {
        lang = this.getLang(lang);
        localStorage.setItem('language', lang);
        if (reload) {
            window.location.reload();
            return;
        }

        // routingState.unloadOne();
        this.translate.use(lang).subscribe(() => {
            // routingState.loadOne();
            if (done) {
                done();
            }
        });

        const dir = lang === 'ar' ? 'rtl' : 'ltr';
        // document.body.dir = dir;
        document.body.setAttribute('dir', dir);
        document.body.parentElement.dataset['textdirection'] = dir;
        if (lang === 'ar') {
            document.body.parentElement.classList.add('rtl-dir');
            document.body.parentElement.classList.remove('ltr-dir');
        }
        else {
            document.body.parentElement.classList.remove('rtl-dir');
            document.body.parentElement.classList.add('ltr-dir');
        }

        this.lang = lang;
        if (lang === 'ar') {
            this.nzI18nService.setLocale(ar_EG);
        }
        else {
            this.nzI18nService.setLocale(en_US);
        }
    }

    private getLang(lang) {
        return ['en', 'ar'].find(a => a === lang) ?? 'en';
    }
}
